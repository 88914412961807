@import url('https://fonts.googleapis.com/css?family=Poppins');

body,html {

  font-family: Poppins, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  min-height: 100%;
  min-width: 100%;
  height: 100%;
  overflow: hidden;
}

.App {
  text-align: center;
}

.App-logo {
  margin-top: 50px;
  height: 3vh;
  pointer-events: none;
  display: flex;
  justify-content: center;
  width: 100vw;
}


.App-header {
  background-color: #f4f4f4;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: start;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
